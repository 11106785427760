import { Link } from 'gatsby'
import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'

// http://www.dktech.cz/?page_id=20

const JakDoHry: FC = () => {
  return (
    <PageTemplate>
      <div className="topTitle">
        <h1><Link to="/jak_do_hry">Jak do hry</Link></h1>
      </div>
      <h3><span style={{ fontSize: '1.17em' }}>Základní příprava</span></h3>
      <p><strong>1. Stáhněte si Scions of Destiny klient:</strong></p>
      <p><a style={{ fontSize: '13px' }} href="http://ulozto.cz/xkJwFP51/lineage-ii-dktech-rar" target="_blank">http://ulozto.cz/xkJwFP51/lineage-ii-dktech-rar</a></p>
      <p><strong>2. Proveďte patch hry patchem pro DKTech.cz server:</strong></p>
      <p><a title="https://dktech.cz/links/patch.php" href="https://dktech.cz/links/patch.php" target="_blank">https://dktech.cz/links/patch.php</a></p>
      <p><strong>3. Proveďte registraci herního účtu zde: </strong></p>
      <p><strong><a title="Registrace" href="https://dktech.cz/links/registration.php">https://dktech.cz/links/registration.php</a></strong></p>
      <p>—————————————————-</p>
      <h3></h3>
      <h3><strong style={{ fontSize: '13px' }}>Zkratky, které se používají v Lineage 2:</strong></h3>
      <p>WTS – want to sell – chci prodat<br />
        WTB – want to buy – chci koupit<br />
        WTT – want to trade – chci vymenit<br />
        PVP – player vs player – souboj mezi dvěma hráči<br />
        PM – private message – soukromá zpráva/šeptání<br />
        GM – good morning / gamemaster<br />
        LVL – level – nová úroveň<br />
        CG – congratulation – gratuluji<br />
        GN – good night – dobrou noc<br />
        PK – player killer – zabiják hráčů<br />
        CS – castle siege – obléhání hradu<br />
        EM – event manager – npc důležité pro výměnu/výhru itemů<br />
        LUX – luxury/prestigious items hall – obchod s luxusním zbožím</p>
      <p><strong>Jak psát v Lineage 2 zprávy?</strong></p>
      <p>Pokud chcete psát veřejně, ale na krátkou vzdálenost stačí napsat pouze obyčejně do chat okna bez jakéhokoliv znaku. BÍLÁ BARVA</p>
      <p>Pro psaní soukromých zpráv po celém světě použijte znak ” a hned za to jméno. Tedy “Pepa (např.). FIALOVÁ BARVA</p>
      <p>Veřejné zprávy, na větší vzdálenost mužete psát takto: !ahoj. ORANŽOVÁ BARVA</p>
      <p>Dále potom můžete přepínat mezi chaty v okně: [All] – [+Trade] – [#Party] – [@Clan] – [$Alliance]</p>
      <div></div>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default JakDoHry

export { Head } from '../components/defaultHtmlHead'